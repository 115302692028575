import { Directive, Input, ViewContainerRef, SecurityContext } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MenuComponent, calcMenuItemBounds } from '../components/menu/menu.component';
import { ulid } from 'ulidx';
import { firstValueFrom } from 'rxjs';
import { MenuOptions, MenuItem } from 'src/app/types/popups';


/**
 * This utils file exists outside of the strict angular DI zone
 * This enables opening popups without requiring absolute DI bindings.
 */

export const getPosition = (el: HTMLElement | PointerEvent, config: any = {}, bounds: DOMRect) => {
    // Bounds of the popup owner
    const src: DOMRect = !!el['nodeName']
        ? (el as HTMLElement).getBoundingClientRect()
        : {
            // It's a pointer event, so we'll take the X and Y from the pointer.
            x: el['clientX'],
            y: el['clientY'],
            // Set a default tiny size, so we don't divide by zero.
            width: 0.0001,
            height: 0.0001
        } as DOMRect;

    // Popup bounds
    const { width, height } = bounds;

    const winh = window.innerHeight;
    const winw = window.innerWidth;

    const cords = {
        top: null,
        left: null
    };

    if (config?.position == "left" || config?.position == "right" || !config?.position) {
        switch (config?.alignment) {

            case "end": {
                // vertically bind to bottom
                cords.top = src.y + src.height - height;
                break;
            }
            case "afterend": {
                // vertically bind below bottom
                cords.top = src.y + src.height;
                break;
            }
            case "beforestart": {
                // vertically bind above top
                cords.top = src.y - height;
                break;
            }
            case "start": {
                // vertically bind to top
                cords.top = src.y;
                break;
            }
            case "center":
            default: {
                // vertically center
                cords.top = (src.y + (src.height / 2)) - (height / 2);
                break;
            }
        }

        // Apply bounds to prevent the dialog from being cut-off screen
        // Lower bound
        cords.top = Math.max(config?.edgePadding || 0, cords.top);
        // Upper bound
        cords.top = Math.min(winh - height, cords.top);

        if (config?.position == "left") {
            cords.left = src.x - (width + (config?.arrowSize || 0) + (config?.arrowPadding || 0));
        }
        if (config?.position == "right" || !config?.position) {
            cords.left = src.x + (src.width + (config?.arrowSize || 0) + (config?.arrowPadding || 0));
        }

        // Lower bound
        cords.left = Math.max(config?.edgePadding || 0, cords.left);
        // Upper bound
        cords.left = Math.min(winw - width, cords.left);
    }
    else if (config?.position == "top" || config?.position == "bottom") {
        switch (config?.alignment) {
            case "end": {
                // vertically bind to right
                cords.left = src.x + src.width - width;
                break;
            }
            case "afterend": {
                // vertically bind past right
                cords.left = src.x + src.width;
                break;
            }
            case "beforestart": {
                // vertically bind before left
                cords.left = src.x - width;
                break;
            }
            case "start": {
                // vertically bind to left
                cords.left = src.x;
                break;
            }
            case "center":
            default: {
                // vertically center
                cords.left = (src.x + (src.width / 2)) - (width / 2);
                break;
            }
        }

        // Apply bounds to prevent the dialog from being cut-off screen
        // Lower bound
        cords.left = Math.max(config?.edgePadding || 0, cords.left);
        // Upper bound
        cords.left = Math.min(winw - width, cords.left);


        if (config?.position == "top") {
            cords.top = src.y - (height + (config?.arrowSize || 0) + (config?.arrowPadding || 0));
        }
        if (config?.position == "bottom") {
            cords.top = src.y + (src.height + (config?.arrowSize || 0) + (config?.arrowPadding || 0));
        }

        // Lower bound
        cords.top = Math.max(config?.edgePadding || 0, cords.top);
        // Upper bound
        cords.top = Math.min(winh - height, cords.top);
    }

    // Assign unit
    cords.top = cords.top + 'px';
    cords.left = cords.left + 'px';

    return cords;
}


@Directive({
    selector: '[ngx-contextmenu],[ngx-menu]',
    providers: [
        MatDialog
    ],
    standalone: true
})
export class MenuDirective {

    /**
     * The data representing the item the menu was opened for.
     */
    @Input("ngx-menu-context") data: any;

    /**
     * The items that will be bound to the context menu.
     */
    @Input("ngx-contextmenu") ctxMenuItems: MenuItem[];

    /**
     * The items that will be bound to the menu that pops
     * up when the user clicks the element.
     */
    @Input("ngx-menu") menuItems: MenuItem[];

    /**
     * Configuration for opening the app menu
     */
    @Input("ngx-menu-config") config: MenuOptions = {};

    constructor(
        private dialog: MatDialog,
        private viewContainer: ViewContainerRef
    ) { }

    ngAfterViewInit() {
        const el = this.viewContainer.element.nativeElement as HTMLElement;

        // Automatically attach context menu items to
        // the contextmenu event
        if (this.ctxMenuItems) {
            el.addEventListener('contextmenu', (e) => {
                e.preventDefault();
                this.openMenu(e as any, this.ctxMenuItems, true);
            });
        }

        if (this.menuItems?.length > 0) {
            if (!this.config?.trigger) {
                el.addEventListener('click', (e) => {
                    this.openMenu(e as any, this.menuItems, true);
                });
            }
            else {
                const triggers = Array.isArray(this.config.trigger) ? this.config.trigger : [this.config.trigger];

                triggers.forEach(t => {
                    if (["contextmenu", "click"].includes(t)) {
                        el.addEventListener(t, (e) => {
                            e.preventDefault();
                            this.openMenu(e as any, this.ctxMenuItems, true);
                        });
                    }
                    else {
                        el.addEventListener(t, this.openMenu.bind(this));
                    }
                });
            }
        }
    }

    async openMenu(evt: PointerEvent, items = this.menuItems, keepOpen = false) {
        const el = this.viewContainer.element.nativeElement as HTMLElement;

        el.classList.add("ngx-menu-open");

        const isCtxEvent = evt.button == 2;

        const config = structuredClone(this.config);
        config['_isLockedOpen'] = keepOpen;

        return openMenu(
            this.dialog,
            items,
            this.data,
            evt,
            this.config,
            isCtxEvent ? null : el
        )
            .then((...res) => {
                el.classList.remove("ngx-menu-open");
                return res;
            })
            .catch((ex) => {
                el.classList.remove("ngx-menu-open");
                throw ex;
            });
    }
}

// Helper to open the menu without using the directive.
export const openMenu = async (
    dialog: MatDialog,
    menuItems: MenuItem[],
    data: any,
    evt: PointerEvent,
    config: MenuOptions = {},
    el?: HTMLElement
) => {
    // console.log({ dialog, menuItems, data, evt, config, el });

    evt.preventDefault();
    evt.stopPropagation();

    // Apply defaults.
    if (!config.alignment)
        config.alignment = "start";

    const initialBounds = await calcMenuItemBounds(menuItems, data);
    const cords = getPosition(el || evt, config, initialBounds);
    const specificId = ulid();

    return firstValueFrom(
        dialog.open(MenuComponent, {
            data: {
                dialog,
                data: data,
                ownerCords: el?.getBoundingClientRect(),
                selfCords: cords,
                items: menuItems,
                config: config,
                id: specificId,
                targetBounds: initialBounds
            },
            panelClass: ["ngx-menu", 'ngx-' + specificId].concat(config?.customClass || []),
            position: cords,
            backdropClass: "ngx-menu-backdrop"
        })
        .afterClosed());
};
